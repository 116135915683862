import styled from "styled-components";

export const $Header = styled.header`
  width: 100%;
  height: auto;
  display: block;
  display: flex;
  padding: 0 1em;
  align-items: center;
  justify-content: center;
  position: relative;
  position: sticky;
  background: #fff;
  z-index: 2;
  box-shadow: 0px 1px 3px #000;

  .logo {
    div {
      svg {
        height: auto;
        width: 180px;

        @media only screen and (max-width: 768px) {
          width: 140px;
        }
      }
    }
  }
  .languages {
    display: flex;
    position: absolute;
    right: 1rem;

    li {
      padding: 1rem;
    }
  }

  .menu {
    position: absolute;
    right: 0;

    a {
      cursor: pointer;
      font-weight: 600;
      font-size: ${(props) =>
        props.menuFont.menuFontSize ? props.menuFont.menuFontSize : "16px"};
      margin: 0.6em;
      color: ${(props) =>
        props.menuFont.menuFontColor ? props.menuFont.menuFontColor : "#000"};
      text-transform: uppercase;

      &.button {
        margin: 0 1em;
        padding: 1em 2.1em;
        width: 282px;
        height: 46px;
        background: transparent
          linear-gradient(105deg, var(--unnamed-color-eb5536) 0%, #ff886f 100%)
          0% 0% no-repeat padding-box;
        background: transparent
          linear-gradient(105deg, #eb5536 0%, #ff886f 100%) 0% 0% no-repeat
          padding-box;
        border-radius: 50px;
        color: #fff;
      }
    }

    @media only screen and (max-width: 1160px) {
      display: flex;
      right: 0;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      height: auto;
      top: ${(props) => (props.menuState === true ? "0%" : "-500%")};
      opacity: ${(props) => (props.menuState === true ? "1" : "0")};
      transition: opacity 0.5s ease-in-out;
      position: absolute;
      z-index: 1;
      background-color: #fff;
      padding: 2em;
      border-bottom-left-radius: 35px;
    }
  }

  .burger-menu,
  .burger-menu.open {
    display: none;

    @media only screen and (max-width: 1160px) {
      display: inline-block;
      cursor: pointer;
      position: absolute;
      right: 1em;
      top: 50%;
      z-index: 1;
      padding: 10px;
      transform: translateY(-50%);
    }
  }

  .burger-menu .bar1,
  .bar2,
  .bar3 {
    width: 1.8rem;
    height: 3px;
    background-color: #333;
    margin: 4px 0;
    transition: 0.4s;
  }

  .burger-menu.open .bar1 {
    transform: rotate(-45deg) translate(-2px, 5px);
  }

  .burger-menu.open .bar2 {
    opacity: 0;
  }

  .burger-menu.open .bar3 {
    transform: rotate(45deg) translate(-5px, -8px);
  }
`;
