import PropTypes from "prop-types";
import React from "react";
import getMenuItems from "./Menu";
import { $Header } from "./SC_Header";
import { ReactSVG } from "react-svg";
import BurgerIcon from "./BurgerIcon";
import { useIntl } from "gatsby-plugin-intl";

const Header = ({ clientName, page, menuDatas }) => {
  const intl = useIntl();
  const [menuState, setMenuState] = React.useState(false);
  return (
    <$Header menuFont={menuDatas} menuState={menuState}>
      {/* {console.log(menuDatas, "clientName")} */}
      <a href="/">
        <ReactSVG
          src={`../../../SVG/${menuDatas.logoUrl}`}
          className="logo"
          alt="logo"
        />
      </a>
      {/* <div className="menu">{getMenuItems(menuDatas.menuItems)}</div> */}
      {page === "ukraine" && (
        <ul className="languages">
          {intl.locale !== "en" && (
            <li>
              <a href="/en/ukraine">English</a>
            </li>
          )}
          {intl.locale !== "uk" && (
            <li>
              <a href="/uk/ukraine">український</a>
            </li>
          )}
          {intl.locale !== "fr" && (
            <li>
              <a href="/fr/ukraine">Français</a>
            </li>
          )}
        </ul>
      )}

      {/* <BurgerIcon open={menuState} onClick={() => setMenuState(!menuState)} /> */}
    </$Header>
  );
};

Header.propTypes = {
  clientName: PropTypes.string,
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  clientName: ``,
  siteTitle: ``,
};

export default Header;
