import PropTypes from "prop-types";
import React from "react";
import DOMPurify from "dompurify";
import { $Footer } from "./SC_Footer";
import { ReactSVG } from "react-svg";
import { useIntl } from "gatsby-plugin-intl";

const Footer = ({
  siteTitle,
  siteUrl,
  footer,
  setAdressBox,
  footerMap,
  footerAdress,
}) => {
  const intl = useIntl();

  const openAdressBox = () => {
    document.body.classList.add("prevent-scroll");
    setAdressBox(true);
  };

  return (
    <$Footer img={"../../../map.jpg"}>
      <div className="info">
        <div className="inner">
          <div className="container">
            <div className="contact">
              <h6>{intl.formatMessage({ id: "Footer.JoinUs" })}</h6>
              <div className="contact-container">
                <div className="contact-list">
                  <a
                    href="https://www.facebook.com/resoluemploi"
                    target="_blank"
                  >
                    <ReactSVG src="../../../SVG/icon-FB.svg"></ReactSVG>
                    <span>www.facebook.com/resoluemploi</span>
                  </a>
                  <a href="https://www.m.me/resoluemploi">
                    <ReactSVG src="../../../SVG/facebook-messenger.svg"></ReactSVG>
                    <span>m.me/resoluemploi</span>
                  </a>
                </div>
                <div className="contact-list">
                  <a href="tel:581-803-6263" target="_blank">
                    <ReactSVG src="../../../SVG/phone.svg"></ReactSVG>
                    <span>581-803-6263</span>
                  </a>
                  <a href="mailto:cvlac@resolutefp.com">
                    <ReactSVG src="../../../SVG/mail-ios.svg"></ReactSVG>
                    <span>cvlac@resolutefp.com</span>
                  </a>
                </div>
              </div>
              {/* <div>m.me/resoluemploi</div>
              <div>581-803-6263</div>
              <div>cvlac@resolutefp.com</div> */}
            </div>
          </div>
        </div>
      </div>
      <div
        className={`map ${footerMap === false ? "none" : "flex"} `}
        onClick={() => openAdressBox()}
      >
        <div className="Overlay"></div>
        <span className="innerText">
          Cliquez ici pour l'adresse de nos usines.
        </span>
      </div>
      <div className="copyright">
        <span>© 2020 Résolu Produits Forestiers - Tous droit réservés</span>
      </div>
    </$Footer>
  );
};

Footer.propTypes = {
  siteTitle: PropTypes.string,
  siteUrl: PropTypes.string,
};

Footer.defaultProps = {
  siteTitle: ``,
  siteUrl: `#`,
};

export default Footer;
